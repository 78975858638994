import Props from "./Props";

const ENV: Props = {
	API_URL: 'https://service.ridevu.com',
	MASTER_API_KEY: '40-5-13f7c',
	SENTRY_LOG_URL: 'https://57b844f9bd4649acb4af143f8dd52e34@o4504099501178880.ingest.sentry.io/4504099504848897',
	UNLEASH_PROXY_URL: 'https://unleash-proxy.privilegemovies.com/proxy',
	UNLEASH_AUTH_KEY: 'JfRpqZdrPVvrkmMZdXeeAMVAUJyd3gvT',
}

export default ENV;