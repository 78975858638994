import { fetchWrapper } from "../utils/endpoint/fetch";
import ENV_CONFIG from "../config/ENV_CONFIG";

export const getFeatureFlags = async (toggles: any) => {
	const requestBody = {
		toggles: toggles,
		context: {
			wlcId: "40",
		},
	};
	const requestHeader = {
		Authorization: ENV_CONFIG.UNLEASH_AUTH_KEY,
		"Content-Type": "application/json",
	};
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(ENV_CONFIG.UNLEASH_PROXY_URL, { method: "POST", headers: requestHeader, body: JSON.stringify(requestBody) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};
