import { useEffect, useState } from "react";
import { LANGUAGE_JSON_STORAGE_KEY, addNewJSONStringsAsKeysIfNeeded, getDateTimePlusHour, getDeviceInfo, globalAny, logout } from "../../utils/Utils";
import useSessionStore from "../../store/useSession.store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys, CarActivity, Themes, Toggles } from "../../Types";
import CustomizeDialogBox from "../DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import Toast from "../Toast";
import { getApiKey } from "../../services/apiKeyService";
import { AppLogger } from "../../utils/AppLogger";
import { EN } from "../../utils/Strings";
import { getLanguage as getLanguageApi } from "../../services/appWordingService";
import useLegalContentStore from "../../store/useLegalContent.store";
import useLoginStore from "../../store/useLogin.store";
import useDrivingStore from "../../store/useDriving.store";
import useGenericContentStore from "../../store/genericContent.store";
import useMqttStore from "../../store/useMqtt.store";
import DebugDialog from "../DialogMessageBox/DebugDialog";
import { Dimensions } from "react-native";
import LandingLoader from "../../components/Loaders/LandingLoader";
import { ScreenDimensions } from "../../utils/enum";
import { breakPointsByLayout } from "../../StyleHelpers";
import { getFeatureFlags } from "../../services/featureFlagService";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
let logoutInterval: NodeJS.Timeout;
let loginInterval: any;
let viewport = document.querySelector("meta[name=viewport]");
let root: any = document.querySelector(':root');
//@ts-ignore
const SessionWrapper = ({ children }) => {
	const [showIdleModal, setShowIdleModal] = useState(false);
	const [showLogout, setShowLogout] = useState(false);
	const [logoutCountDown, setLogoutCountDown] = useState(60);
	const [loginCountDown, setLoginCountDown] = useState(5);
	const isLogout = useSessionStore((state: any) => state.isLogout);
	const setIsLogout = useSessionStore((state: any) => state.setIsLogout);
	const isTimerRunning = useSessionStore((state: any) => state.isTimerRunning);
	const setIsTimerRunning = useSessionStore((state: any) => state.setIsTimerRunning);
	const isSigninLoading = useSessionStore((state: any) => state.isSigninLoading);
	const setIsSigninLoading = useSessionStore((state: any) => state.setIsSigninLoading);
	const fetchLegals = useLegalContentStore((state: any) => state.fetchLegals);
	const fetchAcceptOtherData = useLegalContentStore((state: any) => state.fetchAcceptOtherData);
	const setCurrentTheme = useGenericContentStore((state: any) => state.setCurrentTheme);
	const setIsEnabledFloatingButton = useGenericContentStore((state: any) => state.setIsEnabledFloatingButton);
	const setIsDebugVisible = useGenericContentStore((state: any) => state.setIsDebugVisible);
	const isDebugVisible = useGenericContentStore((state: any) => state.isDebugVisible);
	const setDimensions = useGenericContentStore((state: any) => state.setDimensions);
	const currentDimensions = useGenericContentStore((state: any) => state.dimensions);
	const setCurrentLayout = useGenericContentStore((state: any) => state.setCurrentLayout);

	const setCarActivity = useDrivingStore((state: any) => state.setCarActivity);
	const setIsInitialLoading = useLoginStore((state: any) => state.setIsInitialLoading);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const setCheckingLoginInfo = useLoginStore((state: any) => state.setCheckingLoginInfo);
	const checkingLoginInfo = useLoginStore((state: any) => state.checkingLoginInfo);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoginSuccess = useSessionStore((state: any) => state.isLoginSuccess);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setDeviceName = useMqttStore((state: any) => state.setDeviceName);

	const setIsEnablePayment = usePaymentTransactionStore((state: any) => state.setIsEnablePayment);

	const [allowScale, setAllowScale] = useState(false);

	const onDismissSnackBar = () => {
		setShowLogout(false);
		setIsLogout(false);
		logout();
	};

	const idleLogout = () => {
		setIsLogout(true);
		setShowIdleModal(false);
		setIsTimerRunning(false);
		setLogoutCountDown(60);
	};

	const idleLogin = () => {
		setShowIdleModal(false);
		setLogoutCountDown(60);
		AsyncStorage.setItem(AsyncStorageKeys.idleExpiry, getDateTimePlusHour().toString());
		setIsTimerRunning(true);
	};

	const verifyStorageData = async () => {
		const currentEmail = await AsyncStorage.getItem(AsyncStorageKeys.email);
		const currentSession = await AsyncStorage.getItem(AsyncStorageKeys.session);
		const consumerId = await AsyncStorage.getItem(AsyncStorageKeys.consumerId);
		const currentProfileName = await AsyncStorage.getItem(AsyncStorageKeys.profileName);
		const currentDeviceId = await AsyncStorage.getItem(AsyncStorageKeys.deviceId);
		AsyncStorage.getItem(AsyncStorageKeys.theme).then((value) => setCurrentTheme(value ?? Themes.DARK));
		AsyncStorage.getItem(AsyncStorageKeys.carActivity).then((value) => setCarActivity(value ?? CarActivity.PARKED));
		AsyncStorage.getItem(AsyncStorageKeys.floatingButton).then((value) => setIsEnabledFloatingButton(value == "true"));

		const ready = currentEmail != null && currentSession != null && consumerId != null && currentDeviceId != null;

		if (!ready && window.location.toString().indexOf("landing") < 0) {
			setIsTimerRunning(false);
		}

		if (ready) {
			const deviceInfo = getDeviceInfo();
			if (currentSession) globalAny.SESSIONKEY = currentSession;
			if (consumerId) globalAny.CONSUMERID = consumerId;
			if (currentProfileName && currentDeviceId) {
				globalAny.profileName = currentProfileName;
				setDeviceName(currentProfileName);
				setDeviceInfo(deviceInfo);
			}
		}
	};

	const loadStrings = async () => {
		let strings = localStorage.getItem(AsyncStorageKeys.languageJson);
		if (strings) {
			const parsedLanguages = JSON.parse(strings);
			globalAny.language = { ...EN.Strings, ...parsedLanguages, pass_rules: EN.Strings["pass_rules"] };
			addNewJSONStringsAsKeysIfNeeded();
		}

		try {
			let languageJSON = await getLanguageApi();
			if (languageJSON) {
				// @ts-ignore
				globalAny.language = { ...EN.Strings, ...languageJSON, pass_rules: EN.Strings["pass_rules"] };

				// add keys if they dont exist on server, but do locally
				addNewJSONStringsAsKeysIfNeeded();
				await AsyncStorage.setItem(LANGUAGE_JSON_STORAGE_KEY, JSON.stringify(languageJSON));
			}
		} catch (err) {
			// failing to get json from api is ok
			AppLogger.log(err);
		}
	};

	const loadLegalsAndOtherData = () => {
		try {
			fetchLegals(AsyncStorageKeys.legals);
			fetchAcceptOtherData(AsyncStorageKeys.acceptOtherData);
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const getUnleashFlags = async () => {
		const toggleParams = [Toggles.payment];

		const featureFlags: any = await getFeatureFlags(toggleParams);
		
		if (!featureFlags?.toggles) return;
		
		const togglePayment = featureFlags?.toggles.find((flag: any) => flag.name === Toggles.payment);

		if (togglePayment) setIsEnablePayment(togglePayment.enabled);
	};

	const setUserLoginSessionInfo = async () => {
		const apiKey = await AsyncStorage.getItem(AsyncStorageKeys.apiKey);
		// 1. get api key and country
		try {
			await getApiKey();
			if (apiKey) globalAny.APIKEY = apiKey;
		} catch (err) {
			// no api-key, fatal error
			AppLogger.log(err);
		}

		// 2. verify storage data such as profileName, deviceId, session, email
		await verifyStorageData();
		// 3. load strings from storage
		await loadStrings();
		// 4. Now load legals from server and save.
		loadLegalsAndOtherData();

		await getUnleashFlags();
		setIsInitialLoading(true);

		if (!isLoggedIn) setCheckingLoginInfo(false);
	};

	const screenBreakPoints = (width: number, height: number) => {
		const narrowDimension = breakPointsByLayout?.find((details) => details.layout === ScreenDimensions.narrow);
		let layout = "";
		let scale = "";
		let zoom = "";
		let screenName = "";
		breakPointsByLayout?.forEach((details: any) => {
			details.dimensions.forEach((items: any) => {
				if (items.width === width && items.height === height) {
					layout = details.layout;
					scale = items.scale;
					screenName = items.screenName;
					zoom = items.zoom;
				}
			});
		});

		if (layout) {
			localStorage.setItem("screenDimensions", layout);
			setCurrentLayout(layout);
			viewport?.setAttribute("content", `width=device-width, initial-scale=${scale}, user-scalable=no`);
			root.style.zoom = zoom ?? 1;
		} else {
			const screenDimension = breakPointsByLayout?.find((details: any) => {
				const aspectRatio = details?.aspectRation.split(":");
				const totalRatio = Math.round(Number(aspectRatio[0]) / Number(aspectRatio[1]));
				const totalDimensions = Math.round(width / height);
				if (totalRatio === totalDimensions) return details;
			});
			const screen: any = screenDimension ?? narrowDimension;
			scale = screen.dimensions.find((dimension: any) => dimension.width === width)?.scale ?? 1.0;
			viewport?.setAttribute("content", `width=device-width, initial-scale=${scale}, user-scalable=no`);
			localStorage.setItem("screenDimensions", screen?.layout);
			setCurrentLayout(screen?.layout);

			const zoom = screen.dimensions.find((dimension: any) => dimension.width === width)?.zoom ?? 1;
			root.style.zoom = zoom;
		}
	};

	/**
	 * Checks if the User is already logged in
	 */
	useEffect(() => {
		(async () => {
			// @ts-ignore
			const session = await AsyncStorage.getItem(AsyncStorageKeys.session);
			const apiKey = await AsyncStorage.getItem(AsyncStorageKeys.apiKey);

			if (session && apiKey && isLoggedIn) {
				globalAny.SESSIONKEY = session;
				globalAny.APIKEY = apiKey;
				// Disabled for performance testing
				// const { responseCode }: any = accountCredits();
				// if (!isSessionResponseValid(responseCode)) {
				// 	setIsTimerRunning(false);
				// 	setIsLogout(true);
				// }
			}
			await setUserLoginSessionInfo();
			setIsTimerRunning(true);
		})();
	}, [isLoggedIn]);

	/**
	 * Checks if the User is already logged in
	 */
	useEffect(() => {
		let mounted = true;
		const deviceIdentifier = localStorage.getItem(AsyncStorageKeys.deviceIdentifier);

		if (mounted) {
			if (!deviceIdentifier) localStorage.setItem(AsyncStorageKeys.deviceIdentifier, `Web${new Date().getTime()}`);
		}

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (isLogout) setShowLogout(true);
	}, [isLogout]);

	useEffect(() => {
		clearInterval(logoutInterval);
		logoutInterval = setInterval(() => {
			if (logoutCountDown !== 0) setLogoutCountDown((prevCountDown) => prevCountDown - 1);
		}, 1000);
		if (isTimerRunning) {
			clearInterval(logoutInterval);
			setLogoutCountDown(60);
		}
		return () => {
			clearInterval(logoutInterval);
		};
	}, [showIdleModal, isTimerRunning]);

	useEffect(() => {
		if (showIdleModal) {
			if (logoutCountDown === 0) {
				idleLogout();
			}
		}
	}, [logoutCountDown]);

	useEffect(() => {
		if (isLoginSuccess) {
			clearInterval(loginInterval);
			loginInterval = setInterval(() => {
				if (loginCountDown !== 0) setLoginCountDown((prevCountDown) => prevCountDown - 1);
			}, 1000);
		}
		return () => {
			clearInterval(loginInterval);
		};
	}, [loginCountDown, isLoginSuccess]);

	useEffect(() => {
		setAllowScale(true);
		const subscription = Dimensions.addEventListener("change", ({ window, screen }) => {
			setDimensions({ window, screen });
			setAllowScale(true);
		});

		screenBreakPoints(Dimensions.get('screen').width, Dimensions.get('screen').height);

		//@ts-ignore
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		if (currentDimensions.window && allowScale) {
			screenBreakPoints(currentDimensions.screen.width, currentDimensions.screen.height);
		}
	}, [currentDimensions, viewport]);

	useEffect(() => {
		const sessionExpired = localStorage.getItem(AsyncStorageKeys.sessionExpired);

		if (sessionExpired === "true") setShowLogout(true);
	}, [localStorage.getItem(AsyncStorageKeys.sessionExpired)]);

	if (checkingLoginInfo) {
		return <LandingLoader />;
	}

	return (
		<>
			{children}
			{showIdleModal ? (
				<CustomizeDialogBox
					Header={globalAny.language.modal_logout_header}
					Body={globalAny.language.modal_logout_body.replace("%d", logoutCountDown.toString())}
					ButtonTextLeft={globalAny.language.keep_me_logged_in}
					ButtonTextRight={globalAny.language.logout}
					hasSubTextLeft={true}
					SingleButton={false}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Tertiary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onPressLeft={idleLogin}
					onPressRight={idleLogout}
				/>
			) : (
				<></>
			)}
			{isSigninLoading ? (
				<Toast
					visible={isSigninLoading}
					text={globalAny.language.validating_credentials}
					type={ComponentTypeEnum.Processing}
					onDismissSnackBar={() => setIsSigninLoading(false)}
				/>
			) : (
				<></>
			)}
			{showLogout ? (
				<Toast
					visible={showLogout}
					text={globalAny.language.session_expired}
					type={ComponentTypeEnum.Secondary}
					onDismissSnackBar={onDismissSnackBar}
				/>
			) : (
				<></>
			)}
			{isLoginSuccess ? (
				<Toast
					visible={isLoginSuccess}
					text={`${globalAny.language.login_success} (${loginCountDown}) `}
					type={ComponentTypeEnum.Primary}
					onDismissSnackBar={() => setLoginCountDown(5)}
					duration={6000}
				/>
			) : (
				<></>
			)}
			{isDebugVisible && <DebugDialog onDismiss={() => setIsDebugVisible(false)} />}
		</>
	);
};
export default SessionWrapper;
